import { useFormikContext } from 'formik';
import { InputField } from '../../../shared/formFields/InputField';
import deleteButton from '../../assets/icons/deleteButton.svg';
import { PropertyType, SubWay } from '../../../../types';
import { GroupItemsWrapper, GroupWrapper } from '../PropertyFormStyle';

interface SubwayItemProps {
  index: number;
  subway: SubWay;
  remove: (index: number) => void;
}

export const SubwayItem = ({ index, subway, remove }: SubwayItemProps) => {
  const { values, handleChange, handleBlur, touched, errors } =
    useFormikContext<PropertyType>();
  return (
    <GroupWrapper>
      <InputField
        placeholder=''
        name={`subways[${index}][name]`}
        value={subway.name || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
      />
      <GroupItemsWrapper $width='40%' $gap='5px'>
      <InputField
        placeholder=''
        name={`subways[${index}][distance][text]`}
        value={subway.distance?.text || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
      />
      <InputField
        placeholder=''
        name={`subways[${index}][duration][text]`}
        value={subway.duration?.text || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
      />
      </GroupItemsWrapper>
    </GroupWrapper>
  );
};
