import { ImageListType } from 'react-images-uploading';
import {
  Button,
  Icon,
  GroupWrapper,
  ImagesWrapper,
} from '../PropertyFormStyle';
import deleteButton from '../../../../assets/icons/deleteButton.svg';
import editButton from '../../../../assets/icons/edit.svg';

interface ImageCommonProps {
  onImageUpdate: (updateIndex: number) => void;
  onImageRemove: (deleteIndex: number) => void;
  imageWidth: string;
}

interface ImageItemProps extends ImageCommonProps {
  imageUrl: string;
  index: number;
}
const ImageItem = ({
  imageUrl,
  index,
  onImageUpdate,
  onImageRemove,
  imageWidth,
}: ImageItemProps) => (
  <div>
    <img src={imageUrl} alt='' width={imageWidth} />
    <GroupWrapper>
      <Button type='button' onClick={() => onImageUpdate(index)}>
        <Icon src={editButton} />
      </Button>
      <Button type='button' onClick={() => onImageRemove(index)}>
        <Icon src={deleteButton} />
      </Button>
    </GroupWrapper>
  </div>
);

interface ImagesListProps extends ImageCommonProps {
  imageList: ImageListType;
}
export const ImagesList = ({
  imageList,
  onImageUpdate,
  onImageRemove,
  imageWidth,
}: ImagesListProps) => {
  return (
    <ImagesWrapper>
      {imageList.map((image, index) => (
        <ImageItem
          key={index}
          imageUrl={image.dataURL!}
          index={index}
          onImageUpdate={onImageUpdate}
          onImageRemove={onImageRemove}
          imageWidth={imageWidth}
        />
      ))}
    </ImagesWrapper>
  );
};
