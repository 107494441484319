import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./layouts/header/Header";
import { Footer } from './layouts/header/Footer';

export const AppContainer = styled.div``;

export const Navigation = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
