import { useState, useEffect } from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { BoxButton } from '../../shared/buttons/BoxButton';
import { addEditProperty } from '../../../services/propertiesService';
import { PropertyType } from '../../../types';
import { PropertyFormWrapper, ErrorListWrapper, FormHeader, Icon, StyledLink } from './PropertyFormStyle';
import { PropertyFormTabs } from './PropertyFormTabs';
import { LocationFields } from './locationFields/LocationFields';
import { DescriptionFields } from './DescriptionFields';
import { ContactFields } from './contactsFields/ContactFields';
import { CharacteristicsFields } from './CharacteristicsFields';
import { ImagesFields } from './imagesField/ImagesFields';
import { v4 as uuidv4 } from 'uuid';
import { propertyValidationSchema } from './validationSchema';
import { OfficesFields } from './officesFields/OfficesFields';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import arrow_back from '../../../assets/icons/arrow_back.svg';
import { ButtonGroup } from '../../shared/buttons/ButtonGroup';
import { PromptUnsavedChanges } from '../../shared/PromptUnsavedChanges';
import { ErrorMessageWrapper, SuccessMessageWrapper } from '../../shared/formFields/FormFieldStyles';

const defaultInitialValues: PropertyType = {
  id: uuidv4(),
  name: '',
  shortDesc: '',
  description: '',
  class: '',
  address: '',
  district: '',
  subways: [],
  lat: 0,
  lng: 0,
  gba_m2: 0,
  gla_m2: 0,
  height: 0,
  rent: 0,
  opex: 0,
  currencyOpex: 'UAH',
  ruratio: '',
  currency: 'UAH',
  shelter: false,
  generator: false,
  parking: '',
  lifts: '',
  ventilation: '',
  heating: '',
  fireprotect: '',
  security: '',
  active: true,
  contacts: [],
  contactsForAdmin: [],
  images: [],
  total_m2: 0,
};

export const PropertyForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>('description');
  const [isTotalM2Dirty, setIsTotalM2Dirty] = useState<boolean>(false);
  const { currentToken } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const propertyValues: PropertyType = location.state?.property;
  const bcId = propertyValues ? propertyValues.id : defaultInitialValues.id;
  const pageTitle = propertyValues ? 'Редагувати БЦ' : 'Додати БЦ';
  const initialTotalM2 = propertyValues ? propertyValues.total_m2 : 0;
  const [totalM2, setTotalM2] = useState<number>(initialTotalM2);

  useEffect(() => {
    if (totalM2 !== initialTotalM2) {
      setIsTotalM2Dirty(true);
    } else {
      setIsTotalM2Dirty(false);
    }
  }, [totalM2]);

  const handleSubmit = (values: PropertyType) => {
    setIsSubmitted(false);
    setSuccessMessage(null);
    setErrorMessage(null);
    const submitValues = { ...values, total_m2: totalM2 };
    console.log('submitValues', submitValues);
    addEditProperty(submitValues, currentToken)
      .then((res) => {
        //console.log('res', res);
        setIsSubmitted(true);
        setSuccessMessage('Дані успішно збережені');
      })
      .catch((e) => {
        console.log('error', e);
        setErrorMessage(`Дані не збережені - ${e.message}`);
      });
  };
  const handleClose = () => navigate('/properties');
  return (
    <PropertyFormWrapper>
      <FormHeader>
        <StyledLink to={'/properties'}>
          <Icon src={arrow_back} /> повернутися до списку{' '}
        </StyledLink>
      </FormHeader>
      <h1>{pageTitle}</h1>
      <Formik
        initialValues={propertyValues || defaultInitialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={propertyValidationSchema}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {({ values, errors, touched, dirty, isValid, resetForm }) => {
          console.log('isValid', isValid);
          console.log('errors', errors);
          return (
            <Form
              onChange={() => {
                setIsSubmitted(false);
                setErrorMessage(null);
                setSuccessMessage(null);
              }}
            >
              <PromptUnsavedChanges isDirty={dirty && !isSubmitted} />
              <PropertyFormTabs activeTab={activeTab} setActiveTab={setActiveTab} />
              {activeTab === 'offices' && <OfficesFields bcId={bcId} totalM2={totalM2} setTotalM2={setTotalM2} />}
              {activeTab === 'description' && <DescriptionFields />}
              {activeTab === 'location' && <LocationFields />}
              {activeTab === 'characteristics' && <CharacteristicsFields />}
              {activeTab === 'images' && <ImagesFields />}
              {activeTab === 'contacts' && <ContactFields />}
              {isSubmitted && successMessage && <SuccessMessageWrapper>{successMessage}</SuccessMessageWrapper>}
              {errorMessage && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}
              <ButtonGroup $buttonGap='16px' $marginTop='32px'>
                <BoxButton
                  ariaLabel='add'
                  disabled={(!isTotalM2Dirty && !dirty && !isSubmitted) || !isValid}
                  onClick={() => handleSubmit(values)}
                  $padding='10px 20px'
                  type='submit'
                >
                  Save
                </BoxButton>
                <BoxButton
                  ariaLabel='cancel'
                  disabled={!isTotalM2Dirty && !dirty}
                  onClick={resetForm}
                  $padding='10px 20px'
                  type='button'
                >
                  Reset
                </BoxButton>
                <BoxButton ariaLabel='close' onClick={() => handleClose()} $padding='10px 20px' type='button'>
                  Close
                </BoxButton>
              </ButtonGroup>
            </Form>
          );
        }}
      </Formik>
    </PropertyFormWrapper>
  );
};

const ErrorsList = ({ errors }: { errors: FormikErrors<PropertyType> }) => {
  //console.log('errors', errors)

  const errorsList = Object.entries(errors).map(([key, err]) => {
    if (typeof err === 'object')
      return key === 'contacts' ? 'Виправте помилки у Контактах' : 'Виправте помилки у Контактах для адміністратора';
    return err;
  });
  console.log(errorsList.length);

  return (
    <ErrorListWrapper>
      {errorsList.length > 0 && (
        <div>
          Перед збереженням даних необхідно додати/виправити наступне:
          <ul>
            {errorsList.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        </div>
      )}
    </ErrorListWrapper>
  );
};
