import React, { FC, useEffect, useState } from 'react';
import { useLocation, Location } from 'react-router-dom';
import { logout } from '../../../services/authenticate';
import { useUserContext } from '../../contexts/UserContext';
import { debounce } from '../../../utils/debounce';
import langIco from '../../../assets/icons/lang.svg';
import { ReactComponent as Hamburger } from '../../../assets/icons/hamburger.svg';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import {
  HeaderWrapper,
  LogoWrapper,
  MobilLogoWrapper,
  MobileNavToggle,
  NavLinks,
  NavLink,
  NavSpan,
} from './HeaderStyles';

interface LocationProps {
  path: string;
}
export const Header: FC = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const { currentUser } = useUserContext();

  const listenToScroll = () => {
    debounce(() => {
      const shouldStick = window.scrollY > 0;
      setIsSticky(shouldStick);
    }, 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);

    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const location: Location = useLocation();
  const isHomePage = location.pathname === '/' ? true : false;

  const openMenu = () => setIsMobileNavOpen(true);
  const closeMenu = () => setIsMobileNavOpen(false);

  return (
    <HeaderWrapper $isHomePage={isHomePage} $isSticky={isSticky}>
      <LogoWrapper>Intelligent Property Investments</LogoWrapper>
      <MobileNavToggle onClick={openMenu}><Hamburger /></MobileNavToggle>
      <NavLinks $isMobileNavOpen={isMobileNavOpen}>
      {isMobileNavOpen && <MobilLogoWrapper onClick={closeMenu}><span>Intelligent Property Investments</span><Close /></MobilLogoWrapper>}
        <NavLink to='/'>HOME</NavLink>
        <NavLink to='/'>ABOUT</NavLink>
        <NavLink to='/properties'>PROPERTIES</NavLink>
        {currentUser ? (
          <NavSpan onClick={logout}>SIGN OUT</NavSpan>
        ) : (
          <NavLink to='/auth'>SIGN IN</NavLink>
        )}
        <NavSpan onClick={() => console.log('switch lang')}>
          <img alt='lang' src={langIco} />
        </NavSpan>
      </NavLinks>
    </HeaderWrapper>
  );
};
