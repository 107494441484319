import React from 'react';
import { TabWrapper, Tab } from './PropertyFormStyle';
import { StyledTabName } from '../../shared/formFields/FormFieldStyles';

const formTabs = [
  { key: 'description', name: 'Опис', required:true },
  { key: 'location', name: 'Місцезнаходження', required:true },
  { key: 'characteristics', name: 'Характеристики' },
  { key: 'images', name: 'Зображення' },
  { key: 'offices', name: 'Офіси' },
  { key: 'contacts', name: 'Контакти', required:true },
];

interface Props {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}
export const PropertyFormTabs = ({ activeTab, setActiveTab }: Props) => {
  const changeTab = (name: string) => setActiveTab(name);
  return (
    <TabWrapper>
      {formTabs.map((tab) => (
        <Tab
          key={tab.key}
          onClick={() => changeTab(tab.key)}
          selected={activeTab === tab.key}
        >
          <StyledTabName required={tab.required}>{tab.name}</StyledTabName>
        </Tab>
      ))}
    </TabWrapper>
  );
};
