import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface BoxButtonProps {
  children: any;
  $inverted?: boolean;
  width?: string;
  height?: string;
  fontSize?: string;
  boldFont?: boolean;
  onClick?: (event?: any) => any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  $padding?: string;
  ariaLabel?: string;
}

interface StyledBoxButtonProps {
  $inverted?: boolean;
  width?: string;
  height?: string;
  $padding: string;
}
export const StyledBoxButton = styled.button<StyledBoxButtonProps>`
  padding: ${(props) => props.$padding};
  margin: 20px 0;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.golden};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  color: ${(props) =>
    props.$inverted ? props.theme.darkGreyShadow : props.theme.white};
  background-color: ${(props) =>
    props.$inverted ? props.theme.golden : props.theme.blue};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => !props.height && '45px'};
  transition: color 0.2s ease, background-color 0.2s ease,
    border-color 0.2s ease, width 0.3s ease;

  & svg {
    height: 25px;
  }

  &:disabled {
    color: ${(props) =>
      props.$inverted ? props.theme.black : props.theme.darkGray};
    background-color: ${(props) =>
      props.$inverted ? props.theme.ultraLightGray : props.theme.mediumGray};
    border-color: ${(props) =>
      props.$inverted ? props.theme.ultraLightGray : 'transparent'};
    cursor: default;
    opacity: 0.5  
  } 
  &:hover&:not(:disabled) {
    background-color: ${(props) =>
      props.$inverted ? props.theme.goldenLight : props.theme.softBlue};
    border-color: ${(props) =>
      props.$inverted ? props.theme.goldenLight : 'transparent'};
  }
`;

export const BoxButton: FunctionComponent<BoxButtonProps> = ({
  children,
  $inverted,
  width,
  height,
  onClick,
  disabled,
  type = 'button',
  $padding = '1px 5px',
  ariaLabel,
  ...props
}) => (
  <StyledBoxButton
    $inverted={$inverted}
    width={width}
    height={height}
    onClick={onClick}
    type={type}
    disabled={disabled}
    aria-label={ariaLabel}
    $padding={$padding}
    {...props}
  >
    {children}
  </StyledBoxButton>
);
