import React, { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { SecondScreenWrapper, SliderWrapper, Button, SlideTitle } from './SecondScreenStyles';
import img1 from '../../assets/images/exclusive/1.png';
import img2 from '../../assets/images/exclusive/2.png';
import img3 from '../../assets/images/exclusive/3.png';
import img4 from '../../assets/images/exclusive/4.png';
import img5 from '../../assets/images/exclusive/5.png';

type ItemProps = { src: string; alt: string };
const Item = ({ src, alt }: ItemProps) => (
  <div>
    <img src={src} alt={alt} />
    <SlideTitle>voluptatem quia voluptas</SlideTitle>
  </div>
);
const items = [
  <Item src={img1} alt='slide 1' />,
  <Item src={img2} alt='slide 2' />,
  <Item src={img3} alt='slide 3' />,
  <Item src={img4} alt='slide 4' />,
  <Item src={img5} alt='slide 5' />,
  <Item src={img1} alt='slide 1' />,
  <Item src={img2} alt='slide 2' />,
  <Item src={img3} alt='slide 3' />,
  <Item src={img4} alt='slide 4' />,
  <Item src={img5} alt='slide 5' />,
];

const responsive = {
  0: { items: 1 },
  400: { items: 2 },
  568: { items: 3 },
  768: { items: 4 },
  1024: { items: 5 },
};

export const SecondScreen = () => {
  const carousel = useRef<AliceCarousel>(null);
  return (
    <SecondScreenWrapper>
      <h2>exclusive properties at Intelligent Property Investments</h2>
      <SliderWrapper>
        <AliceCarousel
          mouseTracking
          items={items}
          disableDotsControls
          disableButtonsControls
          responsive={responsive}
          infinite
          ref={carousel}
        />
        <div>
          <Button $position={'left'} onClick={(e) => carousel?.current?.slidePrev(e)} />
          <Button $position={'right'} onClick={(e) => carousel?.current?.slideNext(e)} />
        </div>
      </SliderWrapper>
    </SecondScreenWrapper>
  );
};
