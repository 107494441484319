import React, { FunctionComponent } from 'react';
import { getIn, FormikErrors, FormikTouched } from 'formik';
import { withFormId } from './helpers';
import { FieldLayout, Input, Error, StyledLabel } from './FormFieldStyles';

interface Props {
  name: string;
  value: string | number;
  formId?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  touched?: FormikTouched<{ [field: string]: string | any }>;
  errors?: FormikErrors<{ [field: string]: string | any }>;
  type?: string;
  $margin?: string;
  $width?: string;
}

export const InputField: FunctionComponent<Props> = ({
  name,
  value,
  formId,
  label,
  placeholder,
  disabled = false,
  required = false,
  handleChange,
  handleBlur,
  touched,
  errors,
  type = 'text',
  $margin = '2px 0 5px 0',
  $width = '100%',
}) => {
  const isError = getIn(touched, name) && getIn(errors, name);
  const fieldId = withFormId(name, formId);
  return (
    <FieldLayout $width={$width}>
      {!!label && (
        <StyledLabel
          required={required}
          htmlFor={fieldId}
          disabled={disabled}
          $isError={isError}
        >
          {label}
        </StyledLabel>
      )}
      <Input
        type={type === 'number' ? 'number' : 'text'}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        $margin={$margin}
        id={fieldId}
      />
      {isError && <Error>{getIn(errors, name)}</Error>}
    </FieldLayout>
  );
};
