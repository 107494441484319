import { useState } from 'react';
import { useFormikContext } from 'formik';
import { ImageType, ImageListType } from 'react-images-uploading';
import { PropertyType } from '../../../../types';
import { MultiImagesUploader } from './MultiImagesUploader';
import { addImagesToBc, deleteImage, uploadImage, useImagesByPropertyId } from '../../../../services/propertiesService';
import { useUserContext } from '../../../contexts/UserContext';
import { ImagesWrapper, SubTitle } from '../PropertyFormStyle';
import { SuccessMessageWrapper, ErrorMessageWrapper } from '../../../shared/formFields/FormFieldStyles';
import { getImageBlobData, getImagesBlobData } from '../../../../utils/imagesBlobData';
import { ImageView, ImagesView } from './ImagesView';
import { SingleImageUploader } from './SingleImageUploader';
import { constants } from '../../../../utils/constants';

export const ImagesFields = () => {
  const [uploadedImages, setUploadedImages] = useState<ImageListType | []>([]);
  const [uploadedMainImage, setUploadedMainImage] = useState<ImageListType | []>([]);

  const { values } = useFormikContext<PropertyType>();
  const { currentToken } = useUserContext();
  const bcId = values.id;
  const imagePath = `${values.id}/images/`;
  const mainImageName = constants.MAIN_IMAGE_NAME;

  const { data, mutate } = useImagesByPropertyId(currentToken, bcId);
  const images = data?.data.map((item: string) => item.split('/')[3]);

  const mainImageUrl = images?.find((img: string) => img.includes(mainImageName));
  const buttonTitle = mainImageUrl ? 'Замінити' : 'Завантажити';

  const restImages = images?.filter((img: string) => img !== mainImageUrl) || [];

  const handleUploadImages = async () => {
    const imagesBlobData = getImagesBlobData(uploadedImages);

    try {
      await addImagesToBc(imagesBlobData, bcId, currentToken);
      setUploadedImages([]);
      mutate();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUploadMainImage = async () => {
    const imageBlobData = uploadedMainImage && getImageBlobData(uploadedMainImage[0]);
    const extention = imageBlobData.name && imageBlobData.name.split('.')[1];
    const fileName = mainImageName + extention;
    const payload = imageBlobData.blobData;

    try {
      await uploadImage(bcId, fileName, payload, currentToken);
      setUploadedMainImage([]);
      mutate();
    } catch (e) {
      console.error(e);
    }
  };

  const handleImageRemove = async (imageName: string) => {
    try {
      await deleteImage(bcId, imageName, currentToken);
      mutate();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <SubTitle>Головне зображення:</SubTitle>
      {mainImageUrl && uploadedMainImage.length === 0 && (
        <ImageView image={mainImageUrl} imageWidth={'100'} imagePath={imagePath} />
      )}
      <SingleImageUploader
        uploadedImage={uploadedMainImage}
        setUploadedImage={setUploadedMainImage}
        handleUploadImage={handleUploadMainImage}
        buttonTitle={buttonTitle}
      />
      <SubTitle>Збережені зображення:</SubTitle>
      {restImages.length > 0 && (
        <ImagesView images={restImages} imageWidth={'100'} onImageRemove={handleImageRemove} imagePath={imagePath} />
      )}
      <MultiImagesUploader
        uploadedImages={uploadedImages}
        setUploadedImages={setUploadedImages}
        handleUploadImages={handleUploadImages}
      />
    </>
  );
};
