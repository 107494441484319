import { useNavigate } from 'react-router-dom';
import { useProperties } from '../../services/propertiesService';
import { BoxButton } from '../shared/buttons/BoxButton';
import { useUserContext } from '../contexts/UserContext';
import { PropertiesTable } from './PropertiesTable';
import { Loader } from '../shared/Loader';


export const PropertiesList = () => {
  const { currentToken } = useUserContext();
  const { data: properties, mutate } = useProperties(currentToken);
  const navigate = useNavigate();

  //console.log('properties', properties);
  if(!properties) return <Loader />;
  return (
    <div>
      <PropertiesTable  properties={properties.data} mutate={mutate} />
      <BoxButton
        ariaLabel='add new property'
        onClick={() => navigate('/property-form')}
        $padding='5px'
        type='button'
      >
        Додати БЦ
      </BoxButton>
    </div>
  );
};
