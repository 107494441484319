import React from 'react';
import styled, { css } from 'styled-components';

const ButtonWrapper = styled.div<{ lineLeft?: boolean; center?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;

  ${(props) =>
    props.lineLeft &&
    css`
      border-left: 1px solid;
      border-left-color: ${props.theme.lightGray};
    `};
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `};
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 12px;
  min-height: 32px; // +8px padding, 40px min height
  color: ${(props) => props.theme.marineBlue};
  overflow-wrap: anywhere;
`;

export const TableHeader = styled(TableCell)`
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.marineBlue};
  flex-wrap: wrap;
`;

interface TableRowProps {
  $even: boolean;
  selected?: boolean;
}
export const TableRow = styled.div<TableRowProps>`
  display: contents;
  
  ${TableCell}, ${ButtonWrapper} {
    ${(props) =>
    !props.$even &&
    css`
        background-color: ${props.theme.ultraLightGray};
      `}
    ${(props) =>
    props.selected &&
    css`
        background-color: ${props.theme.whiteBlue};
      `}
  }

  :hover {
    ${TableCell}, ${ButtonWrapper} {
      background-color: ${(props) => props.theme.marineBlue25};
    }
  }
`;

interface TableProps {
  columns?: number;
  $gridTemplateColumns?: string;
}
export const Table = styled.div<TableProps>`
  display: grid;
  ${(props) =>
    props.columns &&
    css`
      grid-template-columns: repeat(${props.columns}, auto);
    `};
  ${(props) =>
    props.$gridTemplateColumns &&
    css`
      grid-template-columns: ${props.$gridTemplateColumns};
    `};
  width: 100%;
  background-color: ${(props) => props.theme.white};
`;

