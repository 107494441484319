import React, { useState, useEffect } from 'react';
import ImageUploading, { ImageListType, ErrorsType } from 'react-images-uploading';
import { Button, ButtonWrapper, Error, Icon, SubTitle } from '../PropertyFormStyle';
import { ImagesList } from './imagesList';
import closeButtonRed from '../../../../assets/icons/closeButtonRed.svg';
import addButtonGreen from '../../../../assets/icons/addButtonGreen.svg';
import { BoxButton } from '../../../shared/buttons/BoxButton';

const MAX_NUMBER = 10;
const ACCEPT_TYPE = ['jpg', 'jpeg', 'gif', 'png'];
const MAX_FILE_SIZE = 10485760; //10mb in binary

interface Props {
  uploadedImages: ImageListType | [];
  setUploadedImages: React.Dispatch<React.SetStateAction<ImageListType>>;
  handleUploadImages: () => void;
}

export const MultiImagesUploader = ({ uploadedImages, setUploadedImages, handleUploadImages }: Props) => {
  const [validationErrors, setValidationErrors] = useState<ErrorsType>(null);

  useEffect(() => {
    setValidationErrors(null);
  }, [uploadedImages]);

  const onChange = (
    imageList: ImageListType
    //addUpdateIndex: number[] | undefined
  ) => {
    //console.log(imageList, addUpdateIndex);
    setUploadedImages(imageList);
  };

  const onError = (errors: ErrorsType, files: ImageListType | undefined) => {
    console.log('errors', errors);
    console.log('files', files);
    setValidationErrors(errors);
  };

  return (
    <ImageUploading
      multiple
      value={uploadedImages}
      onChange={onChange}
      maxNumber={MAX_NUMBER}
      acceptType={ACCEPT_TYPE}
      maxFileSize={MAX_FILE_SIZE}
      onError={onError}
    >
      {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
        return (
          <div>
            {imageList.length > 0 && (
              <>
                <SubTitle>Нові зображення:</SubTitle>
                <ImagesList
                  imageList={imageList}
                  onImageUpdate={onImageUpdate}
                  onImageRemove={onImageRemove}
                  imageWidth={'100'}
                />
              </>
            )}
            <Button type='button' onClick={onImageUpload}>
              <Icon src={addButtonGreen} width={'20px'} />
              Додати зображення
            </Button>

            {validationErrors && (
              <Error>
                {validationErrors.maxNumber && <>Number of selected images exceed maxNumber</>}
                {validationErrors.acceptType && <>Your selected file type is not allow</>}
                {validationErrors.maxFileSize && <>Selected file size exceed maxFileSize</>}
                {validationErrors.resolution && <>Selected file is not match your desired resolution</>}
              </Error>
            )}
            {uploadedImages.length > 0 && (
              <>
                <Button type='button' onClick={onImageRemoveAll}>
                  <Icon src={closeButtonRed} width={'20px'} />
                  Видалити всі
                </Button>
                <BoxButton ariaLabel='add' onClick={handleUploadImages} $padding='10px 20px' type='button'>
                  Зберегти
                </BoxButton>
              </>
            )}
          </div>
        );
      }}
    </ImageUploading>
  );
};
