import useSWR from 'swr';

import { getJsonAsync } from './client';

const url = '/secrets';
const getHeaders = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const useApiKey = (token: string | null) => {
  const headers = token ? getHeaders(token) : null;
  return useSWR(!!headers ? url : null, () => getJsonAsync(url, headers));
};

