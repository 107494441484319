import { useEffect, useRef, useState } from 'react';
import { MapWrapper } from './LocationFieldsStyle';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const defaultOptions = {
  zoomControl: true,
  streetViewControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
};

type MapProps = {
  center: google.maps.LatLngLiteral;
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
  map: google.maps.Map | null;
};

export const Map = ({ center, setMap, map }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const newMap = new window.google.maps.Map(ref.current, {
        mapId: "IPIMAP",
        center,
        zoom: 15,
        ...defaultOptions
      });
      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: newMap,
        position: center,
    });
      setMap(newMap);
    }
  }, [ref, center]);


  return (
    <MapWrapper>
        <div ref={ref} style={containerStyle}>
        </div>
    </MapWrapper>
  )
}

