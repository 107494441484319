import React from 'react';
import Select, { SingleValue } from 'react-select';
import { getIn, FormikErrors, FormikTouched } from 'formik';
import { Option } from '../../../types';
import { Error, StyledLabel, FieldLayout } from './FormFieldStyles';
import { withFormId } from './helpers';

interface SelectFieldProps {
  name: string;
  value: string;
  label?: string;
  formId?: string;
  disabled?: boolean;
  required?: boolean;
  $width?: string;
  options: Option[];
  placeholder?: string;
  handleChange: (name: string, value: any) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldTouched: (name:string) => void;
  touched: any; //FormikTouched<{ [field: string]: boolean }>;
  errors: any; //FormikErrors<{ [field: string]: string }>;
}

export const SelectField = ({
  name,
  value,
  label,
  formId,
  disabled = false,
  required = false,
  options,
  placeholder='',
  handleChange,
  setFieldTouched,
  touched,
  errors,
  $width = 'auto',
}: SelectFieldProps) => {
  
  const getValue = () => {
    if (options.length > 0) {
      return options.find((option) => option.value === value);
    } else {
      return null;
    }
  };

  const onChange = (option: SingleValue<Option>) => {
    const newValue = option?.value ?? '';
    handleChange(name, newValue);
  };
  const isError = getIn(touched, name) && getIn(errors, name);
  const fieldId = withFormId(name, formId);
  return (
    <FieldLayout $width={$width}>
      {!!label && (
        <StyledLabel
          required={required}
          htmlFor={fieldId}
          disabled={disabled}
          $isError={isError}
        >
          {label}
        </StyledLabel>
      )}
      <Select
        name={name}
        value={getValue()}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        onBlur={() =>setFieldTouched(name)}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: '1px solid #7F9DB8',
            height: '45px',
            margin: '2px 0',
          }),
        }}
      />
      {isError ? <Error>{getIn(errors, name)}</Error> : null}
    </FieldLayout>
  );
};
