import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import userpool from '../authentication/userpool';
import { Loader } from '../shared/Loader';

interface UserContextProps {
  currentUser: CognitoUser | null;
  setCurrentUser: (newUser: CognitoUser | null) => void;
  currentToken: string | null;
  setCurrentToken: (token: string | null) => void;
}

const UserContext = createContext<UserContextProps>({
  currentUser: null,
  setCurrentUser: () => {},
  currentToken: null,
  setCurrentToken: () => {},
});

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserContextProviderProps) => {
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
  const [currentToken, setCurrentToken] = useState<string | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await userpool.getCurrentUser();
        setCurrentUser(user);
        if (!!user) {
          user.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              console.log(err);
            } else if (!session?.isValid()) {
              console.log('Invalid session.');
            } else {
                setCurrentToken(session.getIdToken().getJwtToken());
            }
          });
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchCurrentToken = async () => {
      try {
        const user = await userpool.getCurrentUser();
        if (!!user) {
          user.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              console.log(err);
            } else if (!session?.isValid()) {
              console.log('Invalid session.');
            } else {
              setCurrentToken(session.getIdToken().getJwtToken());
            }
          });
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentToken();
  }, [currentUser]);

  const value = { currentUser, setCurrentUser, currentToken, setCurrentToken };

  if (loading) {
    return <Loader />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
