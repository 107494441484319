import { useEffect } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getDetails,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
  AdressSearchContainer,
  Suggestions,
  ListItem,
} from './LocationFieldsStyle';
import { InputField } from '../../../shared/formFields/InputField';

interface AutocompleteFieldProps {
  onSelect: (coordinates: google.maps.LatLngLiteral, place_id: string) => void;
  setFieldValue: (name: string, value: any) => void;
}
export const AutocompleteField = ({
  onSelect,
  setFieldValue,
}: AutocompleteFieldProps) => {
  const { ready, value, init, suggestions, setValue, clearSuggestions } =
    usePlacesAutocomplete({
      callbackName: 'YOUR_CALLBACK_NAME',
      requestOptions: {
        /* Define search scope here */
      },
      initOnMount: false,
      debounce: 300,
    });
  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: { target: { value: string } }) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description, place_id }: { description: string; place_id: string }) =>
    () => {
      // When the user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      //console.log('description', description);
      //console.log('suggestions', suggestions);

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        console.log('📍 Coordinates: ', { lat: lat.toString(), lng: lng.toString() });
        setFieldValue('lat', lat);
        setFieldValue('lng', lng);
        onSelect({ lat, lng }, place_id);
      });

      const parameter = {
        // Use the "place_id" of suggestion from the dropdown (object), here just taking the first suggestion for brevity
        placeId: place_id,
        language: 'uk',
      };

      getDetails(parameter)
        .then((details) => {
          if (typeof details === 'string') {
            console.log('Details: ', details);
          } else {
            console.log('Details: ', details);
            //const street = details.address_components ? details.address_components.filter(com => com.types[0]==='route')[0].long_name : '';
            setFieldValue('address', details.name);
            setFieldValue('district', details.vicinity);
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    };

  const renderSuggestions = () =>
    suggestions.data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItem key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </ListItem>
      );
    });

  useEffect(() => {
    init();
  }, [init]);

  return (
    <AdressSearchContainer ref={ref}>
      <InputField
        name='district'
        value={value}
        handleChange={handleInput}
        label={'Пошук адреси'}
      />

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {suggestions.status === 'OK' && (
        <Suggestions>{renderSuggestions()}</Suggestions>
      )}
    </AdressSearchContainer>
  );
};
