import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const PropertyPageWrapper = styled.div`
min-height: 500px;
  padding: 20px;
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
`;


export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  &:hover{
    color: black;
  }
`;

export const Icon = styled.img`
  cursor: pointer;
`;


