import styled from 'styled-components';

export const FirstScreenWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px 35px;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  text-align: justify;
  font-family: Manrope;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-basis: calc(80% - 30px);
  }
`;

export const ColumnWrapper = styled.div`
  flex-basis: 100%;
  & p{
    margin:10px 0;
  }
  @media (min-width: 768px) {
    flex-basis: calc(50% - 30px);
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  & a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 187px;
    height: 60px;
    background: ${(props) => props.theme.navyBlue};
    margin: 20px;
    color: ${(props) => props.theme.white};
    text-transform: uppercase;
    padding: 7px 20px;
    font-family: Cormorant;
    font-size: 24px;
    font-weight: 700;

    & span {
      display: block;
    }
    & span + span {
      font-size: 16px;
    }
  }
  @media (min-width: 768px) {
    flex-basis: calc(20% - 30px);
    flex-direction: column;
    justify-content: flex-start;
  }
  & :hover {
    color: #c5af85;
  }
`;
