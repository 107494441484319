import styled from 'styled-components';

export const AuthenticationContainer = styled.div`
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 30px auto;
`;

export const FormContainer = styled.div`
  width: 50%;
  margin: 10px;
`;
