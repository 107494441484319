export const mockProperty = {
    "id": "a7391253-b5c6-435b-8b8a-f1f26752c98e",
    "name": "БЦ Капитал ",
    "shortDesc": "В самом сердце деловой активности столицы расположен бизнес-центр класса B+ «Капитал». В Голосеевском районе, на улице Большой Васильковской, 77 - А (старое название - ул. Красноармейская).",
    "description": "<div><b>Technical Specification:</b></div><div><ul><li>Building Management System</li><li>High Speed Elevators</li><li>Café / canteen facilities</li><li>Hydrants and sprinklers system</li><li>Open space</li><li>Power generator to provide energy for life safety systems</li><li>Smoke / heat detectors</li></ul></div><div><br></div><div><b>Rent:</b></div><div><ul><li>Asking rent: <i>20 € / sq. m / month</i> + <u>VAT</u></li><li>Cost of underground parking: 120 € / month</li></ul></div><div><br></div>",
    "class": "Class А",
    "address": "вулиця Велика Васильківська, 77А",
    "district": "Печерський район",
    "subways": [
        {
            "placeId": "ChIJ6UNsDhjP1EARo9lLTVOlnjo",
            "name": "Палац \"Україна\"",
            "distance": {
                "text": "0,6 км",
                "value": 650
            },
            "duration": {
                "text": "9 мин.",
                "value": 519
            }
        },
        {
            "placeId": "ChIJc2For-LO1EARZYVaWcyBCAA",
            "name": "Олімпійська",
            "distance": {
                "text": "0,7 км",
                "value": 653
            },
            "duration": {
                "text": "9 мин.",
                "value": 522
            }
        },
        {
            "placeId": "ChIJ695O2_7O1EARzkbtgj-8fD4",
            "name": "Площа Українських Героїв",
            "distance": {
                "text": "1,5 км",
                "value": 1476
            },
            "duration": {
                "text": "22 мин.",
                "value": 1293
            }
        }
    ],
    "lat": 50.426437,
    "lng": 30.517525,
    "gba_m2": 50,
    "gla_m2": 100,
    "rent": 50,
    "opex": 50.0,
    "currencyOpex": "UAH",
    "ruratio": "2",
    "currency": "UAH",
    "shelter": true,
    "generator": true,
    "parking": "підземна",
    "lifts": "4",
    "ventilation": "Система вентиляції і кондиціонування",
    "active": false,
    "contacts": [
        {
            "firstName": "test",
            "lastName": "test",
            "phone": "1234",
            "email": "test@ss.ss"
        }
    ],
    "contactsForAdmin": [
        {
            "firstName": "test",
            "lastName": "test",
            "phone": "123",
            "email": "test@ss.ss"
        }
    ],
    "images": [
        "a7391253-b5c6-435b-8b8a-f1f26752c98e/kapital1.jpg",
        "a7391253-b5c6-435b-8b8a-f1f26752c98e/kapital2.jpg",
        "a7391253-b5c6-435b-8b8a-f1f26752c98e/kapital3.jpg",
        "a7391253-b5c6-435b-8b8a-f1f26752c98e/kapital4.jpg",
        "a7391253-b5c6-435b-8b8a-f1f26752c98e/office3.jpeg"
    ],
    "total_m2": 0.0,
    "heating": "Опалення"
};
