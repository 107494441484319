import styled, { keyframes } from 'styled-components';

const RingBorderAnimation = keyframes`
  0% {
    stroke-dashoffset: 115;
  }
  to {
    stroke-dashoffset: 0;
  }
`;
export const SliderWrapper = styled.section`
  width: 100%;
  position: relative;

  & ul.alice-carousel__dots {
    position: absolute;
    top: 35%;
    left: 50px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    @media (max-width: 767px) {
      flex-direction: row;
      top: 55%;
      left: 15%;
    }
  }

  & li.alice-carousel__dots-item.__custom {
    cursor: pointer;
    position: relative;
    align-items: center;
    color: transparent;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    margin: 0 10px;
    white-space: nowrap;
    width: 40px;
  }
  & li.alice-carousel__dots-item.__custom > span {
    border: ${(props) => props.theme.border.white};
    border-radius: 100%;
    display: block;
    height: 10px;
    width: 10px;
  }

  & li.alice-carousel__dots-item.__custom.__active {
    border-radius: 100%;
    height: 40px;
    margin: 0 10px;
    position: relative;
    width: 40px;
  }

  & li.alice-carousel__dots-item.__custom.__active > span {
    border: none;
    display: flex;
    height: 40px;
    overflow: hidden;
    position: relative;
    width: 40px;
  }
  & li.alice-carousel__dots-item.__custom.__active > span circle {
    fill: transparent;
    stroke: ${(props) => props.theme.white};
    stroke-width: 1px;
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: ${RingBorderAnimation} 5s linear forwards;
    transform: rotate(-90deg);
    transform-origin: center;
  }

  & li.alice-carousel__dots-item.__custom.__active > span span span {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 18px;
    height: 36px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 1px;
    width: 100%;
  }

  & li.alice-carousel__dots-item.__custom svg {
    display: none;
  }
  & li.alice-carousel__dots-item.__active.__custom svg {
    display: block;
  }
`;

interface SlideWrapperProps {
  $slide: string;
}
export const SlideWrapper = styled.div<SlideWrapperProps>`
  width: 100%;
  height: 0;
  padding-bottom: 58.33%;
  /* (1008 / 1728) * 100% to maintain the aspect ratio */
  background: url(${(props) => props.$slide}) lightgray 50% / cover no-repeat;
`;

export const SliderContentWrapper = styled.div`
  position: absolute;
  left: 15%;
  top: 35%;
  color: #fff;
  font-family: Roboto;
  font-size: 3.3vw;
  //font-weight: 500;
  line-height: 4vw;
  text-transform: uppercase;
  & p {
    background: ${(props) => props.theme.beigeShadow};
    padding: 10px;
    margin: 0;
  }
  & span {
    display: block;
  }
`;
