import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.white};
  background: #062230;
  padding: 48px 0;
  font-family: Cormorant;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  & p {
    font-family: Manrope;
    font-size: 18px;
    text-transform: capitalize;
    margin: 0 0 10px 0;
    @media (max-width: 599px) {
    font-size: 14px;
  }

    @media (max-width: 399px) {
    font-size: 10px;
  }
  }
`;

export const FooterTitle = styled.div`
  color: #fff;
  font-family: Cormorant;
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 10px 0;
  @media (max-width: 599px) {
    font-size: 20px;
  }

  @media (max-width: 399px) {
    font-size: 20px;
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  text-transform: uppercase;
  font-size: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
  text-align: center;
  @media (max-width: 599px) {
    font-size: 18px;
  }

  @media (max-width: 399px) {
    font-size: 16px;
  }

`;
