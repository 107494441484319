import { LogIn } from "./LogIn";
import { SignUp } from "./SignUp";
import { AuthenticationContainer } from "./authenticationStyles";


export const Authentication = () => {
  
    return (
      <AuthenticationContainer>
        <LogIn />
        {/* <SignUp /> */}
      </AuthenticationContainer>
    );
  };
  