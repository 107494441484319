import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonGroupProps {
  children?: any;
  alignRight?: boolean;
  spaceBetween?: boolean;
  reverse?: boolean;
  $marginTop?: '12px' | '16px' | string;
  $buttonGap?: '16px' | '24px' | string;
}

interface ContainerProps {
  alignRight?: boolean;
  $marginTop?: string;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${props => props.alignRight && 'flex-end'};
  margin-top: ${props => props.$marginTop || ''};
`;

interface ButtonsProps {
  reverse?: boolean;
  spaceBetween?: boolean;
  $buttonGap: string;
}
const Buttons = styled.div<ButtonsProps>`
  display: flex;
  flex-flow: ${props => props.reverse ? 'row-reverse' : 'row'};
  ${props => props.spaceBetween && css`
    width: 100%;
    justify-content: space-between;
  `}

  & > button + button {
    margin-left: ${props => !props.reverse && props.$buttonGap};
    margin-right: ${props => props.reverse && props.$buttonGap};
  }
`;

/**
 * ### Wrapper component for grouping multiple buttons together
 *
 * A `<ButtonGroup />` can be placed around a set of buttons to easily control their position
 * relative to one another. The group supports horizontal alignment and order-reversal using
 * Flex, as well as putting space between.
 */
export const ButtonGroup = ({
  children,
  alignRight,
  spaceBetween,
  reverse,
  $marginTop,
  $buttonGap = '16px',
  ...props
} :ButtonGroupProps) => (
  <Container alignRight={alignRight} $marginTop={$marginTop} {...props}>
    <Buttons spaceBetween={spaceBetween} reverse={reverse} $buttonGap={$buttonGap}>
      {children}
    </Buttons>
  </Container>
);