import { useState, useCallback } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { Map } from './Map';
import { AutocompleteField } from './AutocompleteField';
import { InputField } from '../../../shared/formFields/InputField';
import { PropertyType } from '../../../../types';
import { getSubwayStations } from './getSubwayStations';
import { BoxButton } from '../../../shared/buttons/BoxButton';
import { SubwayItem } from './SubwayItem';
import { RowWrapper, ColumnWrapper, GroupWrapper } from '../PropertyFormStyle';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { useApiKey } from '../../../../services/googleMapsApi';
import { useUserContext } from '../../../contexts/UserContext';

const defaultCenter: google.maps.LatLngLiteral = {
  lat: 50.4503596,
  lng: 30.5245025,
};
export const LocationFields = () => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] =
    useState<google.maps.LatLngLiteral>(defaultCenter);
  const [placeId, setPlaceId] = useState<string | null>(null);
  const [isSubwaySearchError, setIsSubwaySearchError] = useState(false);
  const { currentToken } = useUserContext();

  const { data } = useApiKey(currentToken);
  const apiKey = data?.data.api_key;
  const onPlaceSelect = useCallback(
    (coordinates: google.maps.LatLngLiteral, placeId: string) => {
      setCenter(coordinates);
      setPlaceId(placeId);
    },
    []
  );
  const { values, handleChange, handleBlur, setFieldValue, touched, errors } =
    useFormikContext<PropertyType>();
  //console.log('values', values);

  const subwaySearch = async () => {
    try {
      const subwayStations =
        map && placeId && (await getSubwayStations(center, map, placeId));
      if (subwayStations && subwayStations.length > 1) {
        subwayStations.sort((a, b) => {
          const distanceA = a.distance?.value ?? Number.MAX_SAFE_INTEGER;
          const distanceB = b.distance?.value ?? Number.MAX_SAFE_INTEGER;
          return distanceA - distanceB;
        });
        subwayStations.splice(3);
        setFieldValue('subways', subwayStations);
        console.log('subwayStations', subwayStations);
      }
    } catch (error) {
      console.error('Error fetching subway stations:', error);
      setIsSubwaySearchError(true);
    }
  };

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <RowWrapper>
      {apiKey && (
      <Wrapper
        apiKey={apiKey}
        render={render}
        libraries={['places','marker']}
      >
        <ColumnWrapper>
          <AutocompleteField
            onSelect={onPlaceSelect}
            setFieldValue={setFieldValue}
          />
          <GroupWrapper>
            <InputField
              name='lat'
              value={values.lat}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={true}
              label={'Latitude'}
              required
            />
            <InputField
              name='lng'
              value={values.lng}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={true}
              label={'Longitude'}
              required
            />
          </GroupWrapper>
            <Map center={center} map={map} setMap={setMap} />
        </ColumnWrapper>
      </Wrapper>)}
      <ColumnWrapper>
        <InputField
          name='address'
          value={values.address}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          //disabled={true}
          label={'Адреса'}
          required
        />
        <InputField
          name='district'
          value={values.district}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          label={'Район'}
        />
        <div>
          <FieldArray name='subways'>
            {({ remove }) => {
              return (
                <>
                  {values.subways?.length > 0 && <div>Subways:</div>}
                  {values.subways?.map((subway, index) => {
                    return (
                      <SubwayItem
                        key={index}
                        index={index}
                        subway={subway}
                        remove={remove}
                      />
                    );
                  })}
                </>
              );
            }}
          </FieldArray>
          {placeId && (
            <BoxButton
              ariaLabel='add'
              onClick={subwaySearch}
              $padding='5px'
              type='button'
            >
              Find Subway Stations
            </BoxButton>
          )}
          {isSubwaySearchError && <span>Something went wrong, try again</span>}
        </div>
      </ColumnWrapper>
    </RowWrapper>
  );
};
