import useSWR from 'swr';
import { getJsonAsync, deleteJsonAsync, putJsonAsync } from './client';
//const url = '/backend/111';
const getHeaders = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

const getHeadersForImg = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'image/jpeg',
  };
};

export const useProperties = (token: string | null) => {
  const headers = token ? getHeaders(token) : null;
  const url = '/business-centers';
  return useSWR(!!headers ? url : null, () => getJsonAsync(url, headers));
};

export const addEditProperty = async (payload: any, token: string | null) => {
  const headers = token ? getHeaders(token) : null;
  const url = `/business-centers/${payload.id}`;

  const result = !!headers && (await putJsonAsync(url, payload, headers));
  return result;
};

export const deleteProperty = async (id: string, token: string | null) => {
  const headers = token ? getHeaders(token) : null;
  const url = `/business-centers/${id}`;

  const result = !!headers && (await deleteJsonAsync(url, headers));
  return result;
};

///business-centers/{bc_id}/offices:

export const useOfficesByPropertyId = (token: string | null, propertyId?: string | null) => {
  const url = `/business-centers/${propertyId}/offices`;
  const headers = token ? getHeaders(token) : null;
  return useSWR(!!headers && !!propertyId ? url : null, () => getJsonAsync(url, headers));
};

export const addEditOfficeToProperty = async (propertyId: string, payload: any, token: string | null) => {
  const url = `/business-centers/${propertyId}/offices/${payload.id}`;
  const headers = token ? getHeaders(token) : null;
  const result = !!headers && (await putJsonAsync(url, payload, headers));
  return result;
};

export const deleteOffice = async (propertyId: string, officeId: string, token: string | null) => {
  const headers = token ? getHeaders(token) : null;
  const url = `/business-centers/${propertyId}/offices/${officeId}`;

  const result = !!headers && (await deleteJsonAsync(url, headers));
  return result;
};


export const uploadImage = async (
  bcId: string,
  fileName: string,
  payload: any,
  token: string | null,
  officeId?: string
) => {
  const headersForImg = token ? getHeadersForImg(token) : null;
  const commonPath = `/assets/business-centers/${bcId}/`;
  const path = !!officeId ? `${commonPath}offices/${officeId}/images/${fileName}` : `${commonPath}images/${fileName}`;
  const result = !!headersForImg && (await putJsonAsync(path, payload, headersForImg));
  return result;
};

export const addImagesToBc = async (images: any, bcId: string, token: string | null, officeId?: string) => {
  const addImagePromises = images.map(
    async (image: any) => await uploadImage(bcId, image.name, image.blobData, token, officeId)
  );
  return await Promise.all(addImagePromises);
};

export const useImagesByPropertyId = (token: string | null, propertyId: string) => {
  const headers = token ? getHeaders(token) : null;
  const url = `/business-centers/${propertyId}/images`;
  return useSWR(!!headers ? url : null, () => getJsonAsync(url, headers));
};

// /business-centers/{bc_id}/offices/{office_id}/images:

export const useImagesByPropertyIdAndOfficeId = (token: string | null, propertyId: string, officeId: string) => {
  const headers = token ? getHeaders(token) : null;
  const url = `/business-centers/${propertyId}/offices/${officeId}/images`;
  return useSWR(!!headers ? url : null, () => getJsonAsync(url, headers));
};

export const deleteImage = async (
  bcId: string,
  fileName: string,
  token: string | null,
  officeId?: string
) => {
  const headers = token ? getHeadersForImg(token) : null;
  const commonPath = `/business-centers/${bcId}/`;
  const url = !!officeId ? `${commonPath}offices/${officeId}/images/${fileName}` : `${commonPath}images/${fileName}`;
  const result = !!headers && (await deleteJsonAsync(url, headers));
  return result;
};

///business-centers/{bc_id}/offices/{office_id}/images/{image_name} для офисов.
