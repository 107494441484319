import { ImageType, ImageListType } from 'react-images-uploading';

export const getImageBlobData = (image:ImageType) => {
    const binary = image.dataURL && atob(image.dataURL.split(',')[1]);
    const array = [];
    if (binary) {
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
    }
    const blobData = new Blob([new Uint8Array(array)], {
      type: 'image/jpeg',
    });
    const name = image?.file?.name;
    return { blobData, name };
}

export const getImagesBlobData = (uploadedImages:ImageListType) => uploadedImages.map((image: ImageType) => getImageBlobData(image));