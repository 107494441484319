import { Button, Icon, GroupWrapper, ImagesWrapper } from '../PropertyFormStyle';
import deleteButton from '../../../../assets/icons/deleteButton.svg';
import { constants } from '../../../../utils/constants';

interface ImageCommonProps {
  imageWidth: string;
  imagePath: string;
  onImageRemove?: (imageName: string) => void;
}

interface ImageItemProps extends ImageCommonProps {
  image: string;
}

const rootPath = constants.IMAGES_BASE_URL;
//d1cbcddc-eb0e-49d8-b946-db023ba11baf/images/kapital4.jpg
export const ImageView = ({ image, onImageRemove, imageWidth, imagePath }: ImageItemProps) => {
  const imageUrl = `${rootPath}${imagePath}${image}`;
  const imageName = image.split('/').slice(-1).join();
  return (
    <div>
      <img src={imageUrl} alt='' width={imageWidth} />
      <GroupWrapper>
        {onImageRemove && <Button type='button' onClick={() => onImageRemove(imageName)}>
          <Icon src={deleteButton} />
        </Button>}
      </GroupWrapper>
    </div>
  );
};

interface ImagesViewProps extends ImageCommonProps {
  images: string[];
}
export const ImagesView = ({ images, onImageRemove, imageWidth, imagePath }: ImagesViewProps) => {
  return (
    <ImagesWrapper>
      {images.map((image, index) => (
        <ImageView key={index} image={image} onImageRemove={onImageRemove} imageWidth={imageWidth} imagePath={imagePath}/>
      ))}
    </ImagesWrapper>
  );
};
