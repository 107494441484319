import styled from 'styled-components';

export const MapWrapper = styled.div`
  width: 500px;
  height: 300px;
`;

export const AdressSearchContainer = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
`;


export const Suggestions = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  transform: translateY(39px);
  background: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.gray};
  width: 100%;
  list-style: none;
  z-index: 10;
`;

export const ListItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;
`;
