import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Slide1 from '../../assets/images/slide1.png';
import Slide2 from '../../assets/images/slide2.png';
import Slide3 from '../../assets/images/slide3.png';
import Slide4 from '../../assets/images/slide4.png';

import {
  SliderWrapper,
  SlideWrapper,
  SliderContentWrapper,
} from './SlidersStyles';

const items = [
  <SlideWrapper $slide={Slide1} key={1}>
    <SliderContentWrapper>
      <p>
        <span>The #1 Source for</span>
        <span> Luxury Real Estate, find yours.</span>
      </p>
    </SliderContentWrapper>
  </SlideWrapper>,
  <SlideWrapper $slide={Slide2} key={2}>
    <SliderContentWrapper>
      <p>
        <span>Lorem ipsum dolor #2</span>
        <span>Intelligent Property Investments.</span>
      </p>
    </SliderContentWrapper>
  </SlideWrapper>,
  <SlideWrapper $slide={Slide3} key={3}>
    <SliderContentWrapper>
      <p>
        <span>Sed ut perspiciatis #3</span>
        <span>Luxury Real Estate, find yours.</span>
      </p>
    </SliderContentWrapper>
  </SlideWrapper>,
  <SlideWrapper $slide={Slide4} key={4}>
    <SliderContentWrapper>
      <p>
        <span>The #4 Source for</span>
        <span>Luxury Real Estate, find yours.</span>
      </p>
    </SliderContentWrapper>
  </SlideWrapper>,
];
interface RenderDotsItemProps {
  activeIndex: number;
}

const RenderDotsItem = ({ activeIndex }: RenderDotsItemProps) => {
  return (
    <span>
      <span>
        <svg height='40' width='40'>
          <circle r='16' cx='20' cy='20'></circle>
        </svg>
        <span>{activeIndex + 1}</span>
      </span>
    </span>
  );
};

export const Slider = () => {
  return (
    <SliderWrapper>
      <AliceCarousel
        mouseTracking
        items={items}
        disableButtonsControls
        renderDotsItem={RenderDotsItem}
        autoPlay
        infinite
        autoPlayInterval={3500}
        animationDuration={1000}
        animationType='fadeout'
        autoPlayStrategy='none'
      />
    </SliderWrapper>
  );
};
