import styled from 'styled-components';
import { Formik, FormikProps, Form } from 'formik';
import { InputField } from '../shared/formFields/InputField';
import { BoxButton } from '../shared/buttons/BoxButton';

const FormWrapper = styled(Form)`
  display: flex;
  width: 50%;
  @media (max-width: 1100px) {
    width: 70%;
  }
  @media (max-width: 767px) {
    width: 80%;
  }
  justify-content: center;
  padding: 0;
  margin: 15px 0;
  & button {
    font-family: Cormorant;
    font-size: 16px;
    text-transform: uppercase;
    width: 18%;
    @media (max-width: 767px) {
      width: 25%;
    }
    @media (max-width: 500px) {
      font-size: 14px;
      width: 30%;
    }
    @media (max-width: 340px) {
      font-size: 12px;
    }
  }
  & input,
  input:hover,
  input:focus,
  input:active {
    border: ${(props) => props.theme.border.white};
  }
`;

interface FormType {
  email: string;
}

export const SubscriptionForm = () => {
  const initialValues: FormType = {
    email: '',
  };
  const handleSubmit = (values: FormType) => {
    console.log('values', values);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values)}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => {
        return (
          <FormWrapper>
            <InputField
              placeholder='Enter Your Email'
              name='email'
              value={values.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              $margin={'20px 0 5px 0'}
            />

            <BoxButton
              ariaLabel='subscribe'
              onClick={() => handleSubmit(values)}
              $padding='1px 6px'
              type='button'
              $inverted={true}
            >
              Subscribe
            </BoxButton>
          </FormWrapper>
        );
      }}
    </Formik>
  );
};
