import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { authenticate } from '../../services/authenticate';
import { authValidationSchema } from "./authValidationSchema";
import { InputField } from "../shared/formFields/InputField";
import { BoxButton } from "../shared/buttons/BoxButton";
import { FormContainer } from "./authenticationStyles";
import { useUserContext } from "../contexts/UserContext";

export const LogIn = () => {
    const {setCurrentUser} = useUserContext();
    const navigate = useNavigate();

    const handleSubmit = (values: any) => {
        const email = values.email.trim();
        const password = values.password.trim();
        authenticate(email, password)
            .then((data) => {
                setCurrentUser(data);
                navigate('/');
            }, (err) => {
                console.log(err);
            })
            .catch(err => console.log(err))
    };
    const formik = useFormik({
        initialValues: {
            name: "",
            telnum: "",
            email: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: authValidationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });
    return (
        <FormContainer>
            <p>Log in, please:</p>
            <InputField
                placeholder="Email"
                name="email"
                value={formik.values.email}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
            />
            <InputField
                placeholder="Password"
                name="password"
                value={formik.values.password}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
            />
            <BoxButton
                ariaLabel="login"
                onClick={() => handleSubmit(formik.values)}
                $padding="10px 20px"
                type="button"
            >
                Login
            </BoxButton>
        </ FormContainer>
    )
}