import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface HeaderWrapperProps {
  $isHomePage: boolean;
  $isSticky: boolean;
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: ${(props) => (props.$isHomePage ? `fixed` : `sticky`)};
  top: 0;
  z-index: 999;
  width: 100%;
  height: 33px;
  padding: 30px 35px 20px 35px;
  font-family: 'Cormorant';
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px ${(props) => props.theme.blackShadow};
  display: flex;
  align-items: center;
  ${(props) =>
    (!props.$isHomePage || props.$isSticky) &&
    `background-color: ${props.theme.midnightBlue};`}
  ${(props) => props.$isHomePage && `transition: background-color 0.5s ease;`}
`;

export const LogoWrapper = styled.div`
  flex: 1;
  font-size: 1.55vw;
  font-weight: 600;
`;
export const MobilLogoWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;    
  }
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  margin: 15px;
  padding-right: 20px;
  cursor: pointer;
`;

export const MobileNavToggle = styled.div`
  display: block;
  cursor: pointer;
  @media (min-width: 768px) {
    display: none;
  }
`;
interface NavLinksProps {
  $isMobileNavOpen: boolean;
}
export const NavLinks = styled.nav<NavLinksProps>`
  font-size: 0.95vw;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  @media (max-width: 767px) {
    display: ${(props) => (props.$isMobileNavOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: start;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.theme.midnightBlue};
    top: 0;
    left: 0;
    font-size: 16px;
  }
`;
export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  text-decoration: none;
`;
export const NavSpan = styled.span`
  padding: 10px 15px;
  cursor: pointer;
`;
