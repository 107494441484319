import { createGlobalStyle } from 'styled-components';
import Cormorant600 from '../../assets/fonts/cormorant-v21-cyrillic_latin-600.woff2';
import Cormorant700 from '../../assets/fonts/cormorant-v21-cyrillic_latin-700.woff2';
import Khula from '../../assets/fonts/khula-v12-latin-regular.woff2';
import Khula600 from '../../assets/fonts/khula-v12-latin-600.woff2';
import Khula700 from '../../assets/fonts/khula-v12-latin-700.woff2';
import Poppins from '../../assets/fonts/poppins-v20-latin-regular.woff2';
import Manrope from '../../assets/fonts/manrope-v15-cyrillic_cyrillic-ext_latin-regular.woff2';
import Roboto from '../../assets/fonts/roboto-v30-cyrillic_cyrillic-ext_latin-regular.woff2';
import Roboto500 from '../../assets/fonts/roboto-v30-cyrillic_cyrillic-ext_latin-500.woff2';
import Roboto700 from '../../assets/fonts/roboto-v30-cyrillic_cyrillic-ext_latin-700.woff2';

//console.log('Font file path:', Cormorant600);

export const FontStyle = createGlobalStyle`
@font-face {
    font-display: swap;
    font-family: 'Cormorant';
    font-style: normal;
    font-weight: 600;
    src: ${Cormorant600} format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Cormorant';
    font-style: normal;
    font-weight: 700;
    src: ${Cormorant700} format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Khula';
    font-style: normal;
    font-weight: 400;
    src: ${Khula} format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Khula';
    font-style: normal;
    font-weight: 600;
    src: ${Khula600} format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Khula';
    font-style: normal;
    font-weight: 700;
    src: ${Khula700} format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: ${Poppins} format('woff2');
}
/* manrope-regular - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; 
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url(${Manrope}) format('woff2'); 
}
/* roboto-regular - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(${Roboto}) format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(${Roboto500}) format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(${Roboto700}) format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
`;
