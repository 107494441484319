import styled from 'styled-components';
import leftArrow from '../../assets/icons/leftArrow.svg';
import rightArrow from '../../assets/icons/rightArrow.svg';

export const SecondScreenWrapper = styled.section`
  padding: 50px 35px;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  position: relative;

  & ul.alice-carousel__stage {
  }

  & li.alice-carousel__stage-item {
    & img {
      width: 90%;
      margin: 0;
    }
  }
`;
interface ButtonProps {
  $position: 'left' | 'right';
}
export const Button = styled.div<ButtonProps>`
  cursor: pointer;
  position: absolute;
  width: 77px;
  height: 77px;
  top: 30%;
  ${(props) =>
    props.$position === 'left' && `left:0; background: url(${leftArrow});`}
  ${(props) =>
    props.$position === 'right' &&
    `right:2%; background: url(${rightArrow});
    @media (max-width: 1023px) {
      right:2.5%;
    }    
    @media (max-width: 767px) {
      right:3.5%;
    }
    @media (max-width: 567px) {
      right:5%;
    }
    @media (max-width: 399px) {
      right:10%;
    }
    `}
`;

export const SlideTitle = styled.div`
  position: absolute;
  left: 0;
  bottom: 5px;
  height: 77px;
  background: ${(props) => props.theme.darkBlackShadow};
  color: ${(props) => props.theme.white};
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
`;
