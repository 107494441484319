import React from 'react';
import styled, { css } from 'styled-components';
import closeButton from '../../assets/icons/closeButton.svg';

const onClickHandler = (fn) => (evt) => evt.target === evt.currentTarget && fn();

const ModalScroll = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: scroll;
`;

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
`;

const Container = styled.div`
  pointer-events: none;
  background: none;
  position: relative;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
`;

const LayoutArea = styled.div`
  pointer-events: auto;
  width: 100%;
  max-width: 1110px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.size === 'big' &&
    css`
      max-width: 900px;
    `};

  ${(props) =>
    props.size === 'medium' &&
    css`
      max-width: 716px;
    `};

  ${(props) =>
    props.size === 'small' &&
    css`
      max-width: 512px;
    `};
  ${(props) =>
    props.size === 'tiny' &&
    css`
      max-width: 312px;
    `};
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 8px;
  background-color: ${(props) => props.theme.flatWhite};
`;

const ModalTitle = styled.span`
  padding-top: 11px;
  padding-bottom: 13px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue};
`;

const ModalBody = styled.div`
  padding: ${(props) => props.padding ?? '32px'};
  > * ~ * {
    margin-top: 32px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  color: ${(props) => props.theme.blue};
  padding: 5px;
`;

export const Icon = styled.img`
  cursor: pointer;
  margin-bottom: 5px;
`;

export const ModalLayout = ({ children, options = {}, closeModal }) => (
  <ModalScroll>
    <Overlay onClick={onClickHandler(closeModal)}>
      <Container role='dialog'>
        <LayoutArea size={options.size}>
          {options.title && (
            <ModalHeader>
              <ModalTitle>{options.title}</ModalTitle>
              <Button type='button' onClick={closeModal}>
                <Icon src={closeButton} />
              </Button>
            </ModalHeader>
          )}
          <ModalBody padding={options.padding}>{children}</ModalBody>
        </LayoutArea>
      </Container>
    </Overlay>
  </ModalScroll>
);
