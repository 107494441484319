import { FieldArray, useFormikContext } from 'formik';
import { FieldsWrapper } from '../PropertyFormStyle';
import { ContactItem } from '../contactsFields/ContactItem';
import { BoxButton } from '../../../shared/buttons/BoxButton';
import { PropertyType } from '../../../../types';

export const ContactFields = () => {
    const { values, handleChange, handleBlur, setFieldValue, touched, errors } =
    useFormikContext<PropertyType>();
return (
  <>
    <FieldArray name='contacts'>
    {({ push, remove }) => {
      return (
        <FieldsWrapper>
          <div>Contacts:</div>
          {values.contacts?.map((contact, index) => {
            return (
              <ContactItem
                key={index}
                index={index}
                contact={contact}
                remove={remove}
                name={'contacts'}
              />
            );
          })}
          <BoxButton
            ariaLabel='add'
            onClick={() => push('')}
            $padding='5px'
            type='button'
          >
            Add Contact
          </BoxButton>
        </FieldsWrapper>
      );
    }}
  </FieldArray>
  <hr />
  <FieldArray name='contactsForAdmin'>
    {({ push, remove }) => {
      return (
        <FieldsWrapper>
          <div>Contacts For Admin:</div>
          {values.contactsForAdmin?.map((contact, index) => {
            return (
              <ContactItem
                key={index}
                index={index}
                contact={contact}
                remove={remove}
                name={'contactsForAdmin'}
              />
            );
          })}
          <BoxButton
            ariaLabel='add'
            onClick={() => push('')}
            $padding='5px'
            type='button'
          >
            Add Contact For Admin
          </BoxButton>
        </FieldsWrapper>
      );
    }}
  </FieldArray>

  </>
)
}
