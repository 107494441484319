import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../components/authentication/userpool';

export const authenticate = (Email, Password) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Email,
      Pool: userpool,
    });

    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        console.log('login failed', err);
        reject(err);
      },
    });
  });
};
const user = userpool.getCurrentUser();

export const logout = () => {
  console.log('user', user);
  user?.signOut();
  window.location.href = '/auth';
};

