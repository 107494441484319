import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const AuthenticationHandler = ({ children }: any) => {
  const {currentUser} = useUserContext();

  if (!currentUser) {
    console.log('no user')
    return <Navigate to="/auth" />;
  }
  return children;
};

export const ProtectedRoute = () => (
  <AuthenticationHandler>
    <Outlet />
  </AuthenticationHandler>
);

