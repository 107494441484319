import { OfficeType } from '../../../../types';
import { Table, TableCell, TableHeader, TableRow } from '../../../shared/Table';
import editButton from '../../../../assets/icons/edit.svg';
import deleteButton from '../../../../assets/icons/deleteButton.svg';
import styled from 'styled-components';
import { useState } from 'react';
import { OfficeFormModal } from './OfficeFormModal';
import { useUserContext } from '../../../contexts/UserContext';
import { ConfirmDeleteModal } from '../../../shared/ConfirmDeleteModal';
import { deleteOffice } from '../../../../services/propertiesService';

export const Icon = styled.img`
  cursor: pointer;
`;

const OfficesTableHeader = () => {
  return (
    <>
      <TableHeader>
        <span>Поверх</span>
      </TableHeader>
      <TableHeader>
        <span>Площа</span>
      </TableHeader>
      <TableHeader>
        <span>Орендна ставка</span>
      </TableHeader>
      <TableHeader>
        <span>Валюта</span>
      </TableHeader>
      <TableHeader></TableHeader>
      <TableHeader></TableHeader>
      </>
  );
};

interface UserRowProps {
  item: OfficeType;
  $even: boolean;
  bcId: string;
  mutate: any;
  handleShowDeleteModal: (id: string) => void;
}

const OfficeTableRow = ({ item, $even, bcId, mutate, handleShowDeleteModal }: UserRowProps) => {
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  return (
    <>
      {isEditFormVisible && (
        <OfficeFormModal
          bcId={bcId}
          office={item}
          handleCloseModal={() => setIsEditFormVisible(false)}
          mutate={mutate}
        />
      )}
      <TableRow $even={$even}>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.square}</TableCell>
        <TableCell>{item.price}</TableCell>
        <TableCell>{item.currency}</TableCell>
        <TableCell>
          <Icon src={editButton} onClick={() => setIsEditFormVisible(true)} />
        </TableCell>
        <TableCell>
        <Icon src={deleteButton} onClick={() => handleShowDeleteModal(item.id)} />
      </TableCell>

      </TableRow>
    </>
  );
};

export const OfficesTable = ({ offices, bcId, mutate }: { offices: OfficeType[]; bcId: string; mutate: any }) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [deletedOfficeId, setDeletedOfficeId] = useState<string | null>(null);
  const { currentToken } = useUserContext();

  const handleShowDeleteModal = (id: string) => {
    setShowModal(true);
    setDeletedOfficeId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setDeletedOfficeId(null);
  };

  const handleDeleteOffice = async () => {
    try {
      !!deletedOfficeId && (await deleteOffice(bcId, deletedOfficeId, currentToken));
      setDeletedOfficeId(null);
      mutate();
      handleCloseModal();
    } catch (error) {
      setErrorMessage(`Виникла помилка, спробуйте ще. 
        ${error}`);
    }
  };

  return (
    <>
      {showModal && (
        <ConfirmDeleteModal
          handleCloseModal={handleCloseModal}
          handleConfirmDelete={handleDeleteOffice}
          errorMessage={errorMessage}
        />
      )}

      <Table $gridTemplateColumns='3fr 2fr 3fr 1fr 30px 30px'>
        <OfficesTableHeader />
        {offices?.map((office: OfficeType, i: number) => (
          <OfficeTableRow item={office} key={office.id} $even={i % 2 === 0} bcId={bcId} mutate={mutate} handleShowDeleteModal={handleShowDeleteModal}/>
        ))}
      </Table>
    </>
  );
};
