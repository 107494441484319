//import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Home } from './components/home/Home';
import { Navigation } from './components/Navigation';
import { Authentication } from './components/authentication/Authentication';
import { PropertiesList } from './components/properties/PropertiesList';
import { ProtectedRoute } from './components/authentication/ProtectedRoute';
import { PropertyForm } from './components/property/propertyForm/PropertyForm';
import { PropertyPage } from './components/property/propertyPage/PropertyPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Navigation />}>
      <Route path='/auth' element={<Authentication />} />
      <Route element={<ProtectedRoute />}>
        <Route index element={<Home />} />
        <Route path='/properties' element={<PropertiesList />} />
        <Route path='/property-form' element={<PropertyForm />} />
        <Route path='/properties/:propertyId' element={<PropertyPage />} />
      </Route>
    </Route>
  )
);
export const App = () => <RouterProvider router={router} />;

/* export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route path='/auth' element={<Authentication />} />
          <Route element={<ProtectedRoute />}>
            <Route index element={<Home />} />
            <Route path='/properties' element={<PropertiesList />} />
            <Route path='/property-form' element={<PropertyForm />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
 */
