import React, { FC } from 'react';
import { getIn, FormikErrors, FormikTouched } from 'formik';
import { TextArea, Error, FieldLayout, StyledLabel } from './FormFieldStyles';
import { withFormId } from './helpers';

interface Props {
  placeholder?: string;
  name: string;
  value: string;
  formId?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  touched: FormikTouched<{ [field: string]: string | any }>;
  errors: FormikErrors<{ [field: string]: string | any }>;
  rows?: number;
  cols?: number;
  $margin?: string;
  $width?: string;
}

export const TextAreaField: FC<Props> = ({
  placeholder,
  label,
  name,
  value,
  formId,
  disabled = false,
  required = false,
  handleChange,
  handleBlur,
  touched,
  errors,
  rows = 5,
  cols = 20,
  $margin = '2px 0 5px 0',
  $width = '100%',
}) => {
  const isError = getIn(touched, name) && getIn(errors, name);
  const fieldId = withFormId(name, formId);

  return (
    <FieldLayout $width={$width}>
      {!!label && (
        <StyledLabel
          required={required}
          htmlFor={fieldId}
          disabled={disabled}
          $isError={isError}
        >
          {label}
        </StyledLabel>
      )}
      <TextArea
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        rows={rows}
        cols={cols}
      />
      {isError && <Error>{getIn(errors, name)}</Error>}
    </FieldLayout>
  );
};
