import { useState } from 'react';
import { PropertyType } from '../../types';
import { Table, TableCell, TableHeader, TableRow } from '../shared/Table';
import editButton from '../../assets/icons/edit.svg';
import viewButton from '../../assets/icons/eye.svg';
import deleteButton from '../../assets/icons/deleteButton.svg';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ConfirmDeleteModal } from '../shared/ConfirmDeleteModal';
import { deleteProperty } from '../../services/propertiesService';
import { useUserContext } from '../contexts/UserContext';

export const Icon = styled.img`
  cursor: pointer;
`;
const ItemLink = styled(Link)`
  text-decoration: none;
`;

const PropertiesTableHeader = () => {
  return (
    <>
      <TableHeader>
        <span>Name</span>
      </TableHeader>
      <TableHeader>
        <span>Class</span>
      </TableHeader>
      <TableHeader>
        <span>Address</span>
      </TableHeader>
      <TableHeader>
        <span>Active</span>
      </TableHeader>
      <TableHeader></TableHeader>
      <TableHeader></TableHeader>
      <TableHeader></TableHeader>
    </>
  );
};

interface UserRowProps {
  item: PropertyType;
  $even: boolean;
  handleShowDeleteModal: (id: string) => void;
}

const PropertyTableRow = ({ item, $even, handleShowDeleteModal }: UserRowProps) => {
  const navigate = useNavigate();
  return (
    <TableRow $even={$even}>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.class}</TableCell>
      <TableCell>{item.address}</TableCell>
      <TableCell>{item.active ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        <Icon src={viewButton} onClick={() => navigate(`/properties/${item.id}`)} />
      </TableCell>
      <TableCell>
        <Icon src={editButton} onClick={() => navigate('/property-form', { state: { property: item } })} />
      </TableCell>
      <TableCell>
        <Icon src={deleteButton} onClick={() => handleShowDeleteModal(item.id)} />
      </TableCell>
    </TableRow>
  );
};
interface PropertiesTableProps {
  properties: PropertyType[];
  mutate: any;
}
export const PropertiesTable = ({ properties, mutate }: PropertiesTableProps) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [deletedPropertyId, setDeletedPropertyId] = useState<string | null>(null);
  const { currentToken } = useUserContext();

  const handleShowDeleteModal = (id: string) => {
    setShowModal(true);
    setDeletedPropertyId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setDeletedPropertyId(null);
  }

  const handleDeleteProperty = async () => {
    try {
      !!deletedPropertyId && (await deleteProperty(deletedPropertyId, currentToken));
      setDeletedPropertyId(null);
      mutate();
      handleCloseModal();
    } catch (error) {
      setErrorMessage(`Виникла помилка, спробуйте ще. 
        ${error}`);
    }
  };

  return (
    <>
      {showModal && (
        <ConfirmDeleteModal
          handleCloseModal={handleCloseModal}
          handleConfirmDelete={handleDeleteProperty}
          errorMessage={errorMessage}
        />
      )}
      <Table $gridTemplateColumns='3fr 2fr 3fr 1fr 30px 30px 30px'>
        <PropertiesTableHeader />
        {properties?.map((bc: PropertyType, i: number) => (
          <PropertyTableRow item={bc} key={bc.id} $even={i % 2 === 0} handleShowDeleteModal={handleShowDeleteModal} />
        ))}
      </Table>
    </>
  );
};
