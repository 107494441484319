import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { baseTheme } from './utils/styles/theme';
import { GlobalStyle } from './utils/styles/globalStyle';
import { FontStyle } from './utils/styles/fontStyle';
import { UserProvider } from './components/contexts/UserContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={baseTheme}>
      <UserProvider>
          <App />
      </UserProvider>
      <FontStyle />
      <GlobalStyle />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
