import styled from 'styled-components';

export const ThirdScreenWrapper = styled.section`
  padding: 50px 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: 'Manrope';
  font-size: 16px;
  line-height: 32px;
`;

export const TextWrapper = styled.div`
  flex-basis: calc(50% - 20px);
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
`;
export const ImgWrapper = styled.div`
  flex-basis: calc(50% - 20px);
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
  & img{
    max-width: 100%;
  }
`;
