import React, { useState } from "react";
import styled from "styled-components";

interface CheckboxFieldProps {
  disabled?: boolean;
  error?: boolean;
  theme: {
    mediumGray: string;
    red: string;
    marineBlue: string;
  };
}

const CheckboxField = styled.div<CheckboxFieldProps>`
  display: flex;
  flex-direction: row;
  & label {
    display: flex;
    align-items: center;
    line-height: 21px;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    // This is needed to make the text truncation work in the children
    overflow: hidden;
  }
  & input[type="checkbox"]:disabled {
    cursor: default;
  }
  & input[type="checkbox"] {
    position: relative;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 5px 5px 0 0;
    line-height: 21px;
    box-sizing: content-box;
    appearance: none;
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    &::before {
      content: "";
      display: inline-block;
      left: 0px;
      width: 20px;
      height: 20px;
      background: ${(props) =>
        props.disabled ? props.theme.lightGray : props.theme.white};
      border: 1px solid
        ${(props) =>
          props.error
            ? props.theme.red
            : props.disabled
            ? props.theme.mediumGray
            : props.theme.gray};
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
    }
    &:checked::before {
      border-color: ${(props) => props.theme.marineBlue};
      background: ${(props) => props.theme.marineBlue};
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 0px;
      height: 0px;
      bottom: 5px;
      left: 7px;
      border: 1px solid transparent;
      border-bottom-color: ${(props) =>
        props.disabled ? props.theme.lightGray : props.theme.white};
      border-right-color: ${(props) =>
        props.disabled ? props.theme.lightGray : props.theme.white};
      transform: rotate(45deg);
    }
    &:checked::after {
      width: 5px;
      height: 13px;
    }
  }
`;

interface CheckboxProps {
  value: boolean;
  label: string;
  name: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({ value, label, name, handleChange }) => {
  return (
    <div>
      <CheckboxField>
        <label>
          <input
            type="checkbox"
            name={name}
            onChange={handleChange}
            checked={value}
          />
          {label}
        </label>
      </CheckboxField>
    </div>
  );
};
