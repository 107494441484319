import React, { useState, useEffect } from 'react';
import ImageUploading, { ImageListType, ErrorsType } from 'react-images-uploading';
import { Button, DropZone, Error, Icon } from '../PropertyFormStyle';
import { ImagesList } from './imagesList';
import addButtonGreen from '../../../../assets/icons/addButtonGreen.svg';
import { BoxButton } from '../../../shared/buttons/BoxButton';

const MAX_NUMBER = 1;
const ACCEPT_TYPE = ['jpg', 'jpeg', 'gif', 'png'];
const MAX_FILE_SIZE = 10485760; //10mb in binary

interface Props {
  uploadedImage: ImageListType | [];
  setUploadedImage: React.Dispatch<React.SetStateAction<ImageListType>>;
  handleUploadImage: () => void;
  buttonTitle: string;
}

export const SingleImageUploader = ({ uploadedImage, setUploadedImage, handleUploadImage, buttonTitle }: Props) => {
  const [validationErrors, setValidationErrors] = useState<ErrorsType>(null);

  useEffect(() => {
    setValidationErrors(null);
  }, [uploadedImage]);

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    //console.log(imageList, addUpdateIndex);
    setUploadedImage(imageList);
  };

  const onError = (errors: ErrorsType, files: ImageListType | undefined) => {
    console.log('errors', errors);
    console.log('files', files);
    setValidationErrors(errors);
  };

  return (
    <ImageUploading
      value={uploadedImage}
      onChange={onChange}
      maxNumber={MAX_NUMBER}
      acceptType={ACCEPT_TYPE}
      maxFileSize={MAX_FILE_SIZE}
      onError={onError}
    >
      {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
        //console.log('imageList', imageList);
        return (
          <div>
            {imageList.length === 0 && (
              <Button type='button' onClick={onImageUpload}>
                <Icon src={addButtonGreen} width={'20px'} />
                {buttonTitle}
              </Button>
            )}
            <ImagesList
              imageList={imageList}
              onImageUpdate={onImageUpdate}
              onImageRemove={onImageRemove}
              imageWidth={'100'}
            />

            {validationErrors && (
              <Error>
                {validationErrors.maxNumber && <>Number of selected images exceed maxNumber</>}
                {validationErrors.acceptType && <>Your selected file type is not allow</>}
                {validationErrors.maxFileSize && <>Selected file size exceed maxFileSize</>}
                {validationErrors.resolution && <>Selected file is not match your desired resolution</>}
              </Error>
            )}
            {uploadedImage.length > 0 && (
              <>
                <BoxButton ariaLabel='add' onClick={handleUploadImage} $padding='10px 20px' type='button'>
                  Зберегти
                </BoxButton>
              </>
            )}
          </div>
        );
      }}
    </ImageUploading>
  );
};
