import { Slider } from './Slider';
import { FirstScreen } from './FirstScreen';
import { SecondScreen } from './SecondScreen';
import { ThirdScreen } from './ThirdScreen';

export const Home = () => {
  return (
    <>
      <Slider />
      <FirstScreen />
      <SecondScreen />
      <ThirdScreen />
    </>
  );
};
