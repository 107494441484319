import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import { StyledLabel } from './FormFieldStyles';

export const EditorField = ({value, setFieldValue, name, label}) => {

  const onChange = (e) => {
    setFieldValue(name, e.target.value);
  }

  return (
    <EditorProvider>
      {!!label && (
        <StyledLabel>
          {label}
        </StyledLabel>
      )}
      <Editor value={value} onChange={onChange}>
        <Toolbar>
        <BtnUndo />
          <BtnRedo />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikeThrough />
          <Separator />
          <BtnNumberedList />
          <BtnBulletList />
          <Separator />
          <HtmlButton />
        </Toolbar>
      </Editor>
    </EditorProvider>
)
};


/** HTML to React parser
 * https://github.com/remarkablemark/html-react-parser
import parse from 'html-react-parser';
<TableCell>{parse(item.description)}</TableCell>
 * **/
