import { useFormikContext } from 'formik';
import { InputField } from '../../../shared/formFields/InputField';
import deleteButton from '../../../../assets/icons/deleteButton.svg';
import { GroupWrapper, Button, Icon } from '../PropertyFormStyle';
import { Contact, PropertyType } from '../../../../types';

interface ContactItemProps {
  index: number;
  contact: Contact;
  name: string;
  remove: (index: number) => void;
}

export const ContactItem = ({
  index,
  contact,
  name,
  remove,
}: ContactItemProps) => {
  const { handleChange, handleBlur, touched, errors } =
    useFormikContext<PropertyType>();
  return (
    <GroupWrapper>
      <InputField
        label="Ім'я"
        name={`[${name}][${index}][firstName]`}
        value={contact.firstName || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        required
      />
      <InputField
        label='Прізвище'
        name={`[${name}][${index}][lastName]`}
        value={contact.lastName || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        required
      />
      <InputField
        label='Телефон'
        name={`[${name}][${index}][phone]`}
        value={contact.phone || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        required
      />
      <InputField
        label='Email'
        name={`[${name}][${index}][email]`}
        value={contact.email || ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        required
      />

      <Button type='button' onClick={() => remove(index)}>
        <Icon src={deleteButton} /> <span>Remove</span>
      </Button>
    </GroupWrapper>
  );
};
