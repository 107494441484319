import { SubWay } from '../../../../types';

const searchSubwayStationsNearBy = (
  center: google.maps.LatLngLiteral,
  map: google.maps.Map
) => {
  return new Promise<google.maps.places.PlaceResult[] | null>((resolve, reject) => {
    const request: any = {
      location: center,
      type: 'subway_station',
      radius: 2000,
      language: 'uk'
    };

    const service = new google.maps.places.PlacesService(map);
    service.nearbySearch(request, (results:google.maps.places.PlaceResult[] | null, status:google.maps.places.PlacesServiceStatus) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        resolve(results);
      } else if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS){
        resolve([]);
      }
      else {
        reject(status);
      }
    });
  });
};

interface PlaceProps{
  placeId:string;
}

const getDistance = async (origin: PlaceProps, destination: PlaceProps) => {
  const directionsService = new google.maps.DirectionsService();
  try {
    const result = await directionsService.route({
      origin,
      destination,
      travelMode: google.maps.TravelMode.WALKING,
    });
    const data = {
      distance: result.routes[0].legs[0].distance,
      duration: result.routes[0].legs[0].duration,
    };
    return data;
  } catch (error) {
    alert("Could not display directions due to: " + error);
    return {
      distance: null,
      duration: null,
    };
  }
};

export const getSubwayStations = async (
  center: google.maps.LatLngLiteral,
  map: google.maps.Map,
  placeId: string
) => {
  try {
    const searchData = await searchSubwayStationsNearBy(center, map);
    const searchResults: SubWay[] | null =
      searchData &&
      searchData.map((d: any) => ({ name: d.name, placeId: d.place_id }));
    const subways =
      searchResults &&
      (await Promise.all(
        searchResults.map(async (sub) => {
          const dist = await getDistance({ placeId }, { placeId: sub.placeId });
          const newSub = {
            ...sub,
            distance: dist.distance,
            duration: dist.duration,
          };
          return newSub;
        })
      ));
    return subways;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};


