import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { ModalLayout } from './ModalLayout';
import { ButtonGroup } from './buttons/ButtonGroup';
import { BoxButton } from './buttons/BoxButton';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const modalOptions = {
  size: 'small',
  title: '',
};

export const PromptUnsavedChanges = ({ isDirty }: { isDirty: boolean }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname
  );
  return (
    <>
      {blocker.state === 'blocked' ? (
        <ModalLayout closeModal={() => blocker.reset()} options={modalOptions}>
          <Wrapper>
            <p>Ви впевнені, що хочете піти? У вас є незбережені зміни.</p>
          </Wrapper>

          <Wrapper>
            <ButtonGroup $buttonGap='16px' $marginTop='32px'>
              <BoxButton
                ariaLabel='proceed'
                onClick={() => blocker.proceed()}
                $padding='10px 20px'
                type='button'
              >
                Піти
              </BoxButton>
              <BoxButton
                ariaLabel='cancel'
                onClick={() => blocker.reset()}
                $padding='10px 20px'
                type='button'
              >
                Залишитися
              </BoxButton>
            </ButtonGroup>
          </Wrapper>
        </ModalLayout>
      ) : null}
    </>
  );
};
