/* eslint-disable no-multi-spaces */

export const baseTheme = {
  flatWhite: '#F5F5F5',
  white: '#FFF',
  black: '#000000',
  lightGold: '#C5AF85',
  midnightBlue: '#062230',
  navyBlue: '#051b25',
  marineBlue: '#003C71',
  marineBlue75: '#33638D',
  marineBlue50: '#7f9db8',
  marineBlue25: '#A3B9CC',
  blue: '#003A4D',
  softBlue: '#4887B1',
  whiteBlue: '#D9E3EC',
  lightBlue: '#ECF1F5',
  gray: '#7F9DB8',
  ultraLightGray: '#F3F4F6',
  darkGray: '#212121',
  mediumGray: '#C9CDD0',
  lightGray: '#DEDEDE',
  red: '#AD1F23',
  green: '#73C6A1',
  yellow: '#F6C525',
  golden: '#C5AF85',
  goldenLight: '#EDD6AD',
  blackShadow: 'rgba(0, 0, 0, 0.25)',
  darkBlackShadow: 'rgba(0, 0, 0, 0.55)',
  darkGreyShadow: 'rgba(0, 0, 0, 0.75)',
  beigeShadow: 'rgba(204, 187, 153, 0.8)',
  border: {
    white: '1px solid #FFF',
    gray: '1px solid #7F9DB8',
    blue: '1px solid #2684ff'
  },
};

export type BaseTheme = typeof baseTheme;
