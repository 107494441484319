import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import load from '../../assets/icons/load.svg';

interface LoaderProps {
  size?: LoaderSize;
  color?: string;
}

type LoaderSize = 'large' | 'medium' | 'small';
function getWidth(size?: LoaderSize) {
  switch (size) {
    case 'small':
      return '24px';
    case 'medium':
      return '60px';
    case 'large':
    default:
      return '80px';
  }
}
const Load = styled.img``;
const Wrapper = styled.div<{ color?: string; }>`
  display: flex;
  justify-content: center;
  color: ${props => props.color || props.theme.marineBlue};
  & svg circle {
    fill: currentColor;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;  
  width: 70px;
  height: 48px;
  padding: 10px 20px;
  box-sizing: border-box;
  align-items: center;
  color: ${props => props.theme.marineBlue};
  & svg circle {
    fill: currentColor;
  }
`;

const PageWrapper = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;  
  align-items: center;
  color: ${props => props.theme.marineBlue};
  & svg circle {
    fill: currentColor;
  }
`;

export const Loader: FunctionComponent<LoaderProps> = ({ size, color }) => {
  const width = getWidth(size);
  return (
    <Wrapper color={color}>
      <Load src={load} width={width} height="16px" alt='loading' />
    </Wrapper>
  );
};

export const SmallLoader: FunctionComponent = () => (
  <Wrapper color="inherit">
    <img src={load} width="24px" height="16px" alt='loading' />
  </Wrapper>
);

export const ButtonLoader: FunctionComponent = () => (
  <ButtonWrapper>
    <Load src={load} width="80px" height="16px" alt='loading' />
  </ButtonWrapper>
);

export const PageLoader: FunctionComponent = () => (
  <PageWrapper>
    <Load src={load} width="140px" height="32px" alt='loading' />

  </PageWrapper>
);
