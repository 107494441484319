import { FooterWrapper, TopWrapper, BottomWrapper, FooterTitle } from './FooterStyles';
import { SubscriptionForm } from '../../subscription/SubscriptionForm';

export const Footer = () => (
  <FooterWrapper>
    <TopWrapper>
      <FooterTitle>Know everything that hits the market</FooterTitle>
      <p>Subscribe here to get the latest news about our properties</p>
      <SubscriptionForm />
    </TopWrapper>
    <BottomWrapper>about Intelligent Property Investments</BottomWrapper>
  </FooterWrapper>
);
