import { useFormikContext } from 'formik';
import { InputField } from '../../shared/formFields/InputField';
import { TextAreaField } from '../../shared/formFields/TextAreaField';
import { SelectField } from '../../shared/formFields/SelectField';
import { Checkbox } from '../../shared/formFields/Checkbox';
import { PropertyType } from '../../../types';
import { classOptions } from './options';
import {
  GroupWrapper,
  ColumnWrapper,
  RowWrapper,
  SelectWrapper,
  CheckboxWrapper,
} from './PropertyFormStyle';
import { EditorField } from '../../shared/formFields/EditorField';

export const DescriptionFields = () => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<PropertyType>();
  return (
    <RowWrapper>
      <ColumnWrapper>
        <InputField
          label='Назва'
          name='name'
          value={values.name}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          required
        />
        <TextAreaField
          label='Короткий опис'
          name='shortDesc'
          value={values.shortDesc}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          rows={8}
        />
        <GroupWrapper>
          <SelectWrapper>
            <SelectField
              name='class'
              label='Виберіть клас БЦ'
              value={values.class}
              options={classOptions}
              handleChange={setFieldValue}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              required
            />
          </SelectWrapper>
          <CheckboxWrapper>
            <Checkbox
              label={'Опубліковано'}
              name={'active'}
              handleChange={handleChange}
              value={values.active}
            />
          </CheckboxWrapper>
        </GroupWrapper>
      </ColumnWrapper>
      <ColumnWrapper>
        <EditorField
          value={values.description}
          setFieldValue={setFieldValue}
          name={'description'}
          label='Переваги'
        />
      </ColumnWrapper>
    </RowWrapper>
  );
};
