import styled, { css } from 'styled-components';
import { ModalLayout } from './ModalLayout';
import { ButtonGroup } from './buttons/ButtonGroup';
import { BoxButton } from './buttons/BoxButton';

export const Error = styled.div`
  color: ${(props) => props.theme.red};
  font-size: 12px;
  display: flex;
  justify-content: center;
`;

interface ConfirmDeleteModalProps {
  handleCloseModal: () => void;
  handleConfirmDelete: () => void;
  errorMessage?: string | null;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ConfirmDeleteModal = ({ handleCloseModal, handleConfirmDelete, errorMessage }: ConfirmDeleteModalProps ) => {
  const modalOptions = {
    size: 'tiny',
    title: 'Підтвердити видалення',
  };

  return (
  <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
    <Wrapper>
      Ви впевнені, що хочете видалити це?
    </Wrapper>
    {errorMessage && <Error>{errorMessage}</Error>}
    <Wrapper>
            <ButtonGroup $buttonGap='16px' $marginTop='32px'>
              <BoxButton
                ariaLabel='proceed'
                onClick={handleConfirmDelete}
                $padding='10px 20px'
                type='button'
              >
                Видалити
              </BoxButton>
              <BoxButton
                ariaLabel='cancel'
                onClick={handleCloseModal}
                $padding='10px 20px'
                type='button'
              >
                Скасувати
              </BoxButton>
            </ButtonGroup>
          </Wrapper>
  </ModalLayout>
  )
};
